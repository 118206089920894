<script>
	import { onMount } from "svelte";
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uuid = urlParams.get("uuid")
	const partyNum = parseInt(urlParams.get("pnum"))
	let simplePos = true;
	let simplePosText;

	let formFieldContent = {
		area: [
			{
				id: 1,
				text: "Privat (z. B. Miete, Nachbarschaft, Verbraucherschutz)"
			},
			{
				id: 2,
				text: "Arbeit (z. B. Gehalt, Konflikt am Arbeitsplatz)"
			},
			{
				id: 3,
				text: "Wirtschaft (z. B. Geschäftskonflikt, Preisverhandlung, Zahlungsrückstand)"
			}
		],
		priorities: [
			{
				id: 1,
				points: 100,
				text: ""
			}
		],
		relationshipGrade: [
			{
				id: 1,
				text: "Eng"
			},
			{
				id: 2,
				text: "Locker"
			},
			{
				id: 3,
				text: "Schon lange bestehend"
			},
			{
				id: 4,
				text: "Soll in Zukunft fortbestehend"
			}
		]
	};
	let pointOverhead = false;
	$: {
		if(formFieldContent.priorities.reduce((partialSum, a) => partialSum + a.points, 0) > 100) {
			pointOverhead = true;
		}
	}
	let partyPosition = {
		importanceRelationship: 5,
		solutionUrgency: 5,
		conflictSeverity: 5
	};
	let otp;

	let status = {};

	function addPriority() {
		formFieldContent.priorities = [
			...formFieldContent.priorities,
			{
				id: formFieldContent.priorities.length + 1,
				points: 0,
				text: ""
			}
		];
	}

	function checkPointMax(prioPoints, prioIndex) {
		let overhead = formFieldContent.priorities.reduce((partialSum, a) => partialSum + a.points, 0) - 100;
		if(!overhead || overhead <= 0) return;
		formFieldContent.priorities[prioIndex].points = prioPoints - overhead;
	}
	
	function checkAddPrio(event) {
		if(event.key !== "Enter") return
		addPriority();
	}

	onMount(() => {
		fetch(
			"/api/status?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			console.log(j);
			if(j.neg_info[3] > 0) {
				alert("Die Verhandlung läuft bereits. Sie können Ihre Position nicht mehr verändern!");
				return;
			}

			status.topic = j.neg_info[1];
			status.partyInfo = JSON.parse(j.neg_info[2])[partyNum - 1];
		})
	})

	function checkForNegotiationStart() {
		fetch(
			"/api/status?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			if(j.neg_info[3] > 0) {
				alert("Die Verhandlung hat begonnen, Sie werden weitergeleitet!");
				window.location.replace(
					`/de/overview?uuid=${uuid}`
				);
			}
		})
	}

	function setPartyPosition() {
		let fullPosition;
		if(simplePos) {
			fullPosition = {
				simplePosText
			}
		}
		else {
			fullPosition = {
				...partyPosition,
				priorities: formFieldContent.priorities
			};
			fullPosition.area = fullPosition.area.text;
			fullPosition.relationshipGrade = fullPosition.relationshipGrade.text;
		}
		
		fetch(
			"/api/setposition?" + new URLSearchParams({
				uuid,
				party_num: partyNum,
				otp: otp.trim(),
				position: JSON.stringify(fullPosition)
			})
		).then(res => res.json()).then(j => {
			if(j.success) {
				alert("Ihre Position wurde erfolgreich gesetzt. Sie werden weitergeleitet...")
				window.location.replace(
					`/de/overview?uuid=${uuid}`
				)
			}
			else {
				alert(j.msg);
			}
		});
		setTimeout(checkForNegotiationStart, 5000);
	}
</script>

<main class="flex flex-col w-screen min-h-screen bg-base p-4">
	<h1 class="font-bold text-2xl mt-4 text-textcol">Get Your Deal: Position setzen</h1>
	<p class="text-textcol mt-2">Verhandlungs-Code: {uuid}</p>
	{#if status.partyInfo}
		<p class="font-bold mt-4 text-textcol">Position setzen für {status.partyInfo.name} ({status.partyInfo.email})</p>
		<p class="font-bold mt-4 text-textcol">Thema der Verhandlung</p>
		<p class="text-textcol">{status.topic}</p>

		<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Ihre Position</h2>
		
		<div class="flex flex-col w-full md:landscape:w-2/3 place-self-center">
			<div class="flex flex-row w-full justify-center"
				title="Im vereinfachten Modus geben Sie Ihre Position als Freitext ein. Ansonsten werden Sie durch einen Fragebogen geleitet, der Ihre Positionsdarstellung optimiert.">
				<p class="text-textcol">Vereinfachter Modus</p>
				<input type="checkbox" bind:checked={simplePos}
				class="ml-2 toggle toggle-md bg-accent hover:bg-elevated"/>
			</div>
			{#if simplePos}
			<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">Kurzbeschreibung</h3>
			<p class="mt-2 text-textcol">Bitte stellen Sie den Konflikt und Ihre Position möglichst kurz dar.</p>
			<textarea 
				class="p-2 rounded-md w-full h-64 bg-elevated text-textcol placeholder-textcol" 
				placeholder="Beschreiben Sie kurz Ihre Position."
				bind:value={simplePosText}
				maxlength="30000"
				></textarea>
				
			{:else}
				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">1. Bereich der Verhandlung</h3>
				<p class="mt-2 text-textcol">Welcher Bereich beschreibt am besten die Art Ihres Konflikts oder der Verhandlung?</p>
				<select class="p-2 text-textcol bg-elevated" bind:value={partyPosition.area}>
				{#each formFieldContent.area as a}
					<option value={a}>{a.text}</option>
				{/each}
				</select>

				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">2. Grundlegende Informationen</h3>
				<p class="mt-2 text-textcol">Was ist der Hauptgrund für den Konflikt oder die Verhandlung?</p>
				<textarea 
					class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie das Thema in einem Satz, z. B. Preisverhandlung, Vertragsstreit, Beziehungsproblem."
					bind:value={partyPosition.mainReason}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Wann hat dieser Konflikt begonnen?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Datum oder Zeitraum"
					bind:value={partyPosition.startDate}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Welche Ergebnisse möchten Sie erreichen und mit welcher Priorität (verteilen Sie bis zu 100 Punkte)?</p>
				{#each formFieldContent.priorities as prio, i}
					<div class="flex flex-row items-center mt-2">
						<input class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol" 
							placeholder="z. B. Preis, Zeitrahmen, Vertragsdetails, emotionale Aspekte" 
							on:keyup={checkAddPrio}
							bind:value={prio.text}
							autofocus={i > 0 && i === formFieldContent.priorities.length -1}/>
						<p class="mx-2 text-textcol">Punkte: </p>
						<input class="p-2 rounded-md text-textcol bg-elevated"  type="number" min="0" max="100" bind:value={prio.points} 
						on:input={checkPointMax(prio.points, i)}/>
					</div>
				{/each}
				<button
					hidden={formFieldContent.priorities.length > 4}
					class="btn rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol mt-2"
					on:click={addPriority}>
					<i class="fa-solid fa-user-plus text-inherit"></i>
					<p class="ml-2 text-inherit">Priorität hinzufügen</p>
				</button>

				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">3. Ihre Position und Interessen</h3>
				<p class="mt-2 text-textcol">Was sind Ihre wichtigsten Interessen in dieser Verhandlung (bitte nicht Positionen, sondern zugrunde liegende Interessen nennen)?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beispiel: Sicherheit, langfristige Zusammenarbeit, fairer Preis, Respekt"
					bind:value={partyPosition.mainInterests}
					maxlength="200"
					></textarea>
				<p class="mt-2 text-textcol">Warum sind diese Interessen für Sie wichtig?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beispiel: «Ich brauche Planungssicherheit, weil meine Firma auf regelmäßige Lieferungen angewiesen ist.»"
					bind:value={partyPosition.mainInterestsReasons}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Welche Aspekte dieser Verhandlung sind für Sie nicht verhandelbar?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie, was Sie in keinem Fall ändern oder aufgeben können."
					bind:value={partyPosition.nonNegotiable}
					maxlength="200"
					></textarea>
				<p class="mt-2 text-textcol">Welche Bedingungen müssen erfüllt sein, damit Sie einer Lösung zustimmen?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beispiel: Ein bestimmter Rabatt, Fristenverlängerung, Entschuldigung"
					bind:value={partyPosition.zopa}
					maxlength="200"
					></textarea>
				<p class="mt-2 text-textcol">Gibt es Bedingungen, unter denen Sie sich weigern würden, weiter zu verhandeln?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Geben Sie die Bedingungen hier an. Falls es keine Bedingungen gibt, lassen Sie das Feld frei."
					bind:value={partyPosition.nogos}
					maxlength="200"
					></textarea>
				<p class="mt-2 text-textcol">Welches sind Ihrer Meinung nach gemeinsame Interessen zwischen Ihnen und der anderen Partei?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beispiele: Langfristige Zusammenarbeit, Kostenreduktion, Erhalt der Beziehung, Vermeidung von rechtlichen Schritten"
					bind:value={partyPosition.commonInterests}
					maxlength="300"
					></textarea>

				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">4. Emotionale und beziehungsrelevante Aspekte</h3>
				<p class="mt-2 text-textcol">Gibt es emotionale Faktoren, die diese Verhandlung beeinflussen?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Z. B. Frustration, Vertrauen, Misstrauen, Angst"
					bind:value={partyPosition.emotionalFactors}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Wie wichtig ist Ihnen der Erhalt der Beziehung zur anderen Partei?</p>
				<input type="range" min="1" max="10" bind:value={partyPosition.importanceRelationship}/>
				<p class="mt-2 text-textcol">Wie eng ist Ihre Beziehung zur anderen Partei?</p>
				<select class="p-2 text-textcol bg-elevated" bind:value={partyPosition.relationshipGrade}>
				{#each formFieldContent.relationshipGrade as g}
					<option value={g}>{g.text}</option>
				{/each}
				</select>

				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">5. Alternativen zur Verhandlung</h3>
				<p class="mt-2 text-textcol">Was ist Ihre beste Alternative, falls keine Einigung erzielt wird?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie Ihre beste Alternative, z. B. ein anderer Vertragspartner, rechtliche Schritte."
					bind:value={partyPosition.batna}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Was ist Ihre schlimmste Alternative, falls keine Einigung erzielt wird?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie Ihre schlimmste Alternative, z. B. rechtlicher Verlust, Beziehungsabbruch."
					bind:value={partyPosition.watna}
					maxlength="300"
					></textarea>
				<p class="mt-2 text-textcol">Was ist Ihre realistischste Alternative, falls keine Einigung erzielt wird?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie, was Sie realistisch erwarten, falls keine Einigung erzielt wird."
					bind:value={partyPosition.ratna}
					maxlength="300"
					></textarea>

				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">6. Dringlichkeit und Eskalationsgrad</h3>
				<p class="mt-2 text-textcol">Wie dringend ist die Lösung dieses Konflikts für Sie?</p>
				<input type="range" min="1" max="10" bind:value={partyPosition.solutionUrgency} class="p-2 text-textcol"/>
				<p class="mt-2 text-textcol">Wie ernst ist der Konflikt in Ihrem Empfinden (Eskalationsgrad)?</p>
				<input type="range" min="1" max="10" bind:value={partyPosition.conflictSeverity} class="p-2 text-textcol"/>
				<p class="mt-2 text-textcol">Ist der Konflikt bereits eskaliert?</p>
				<select class="p-2 text-textcol bg-elevated" bind:value={partyPosition.escalation}>
					<option value="Ja">Ja</option>
					<option value="Nein">Nein</option>
					<option value="Teilweise">Teilweise</option>
				</select>
				<p class="mt-2 text-textcol">Falls ja, welche Schritte wurden bereits unternommen?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Z. B. Drohungen, rechtliche Schritte, persönliche Angriffe"
					bind:value={partyPosition.previousSteps}
					maxlength="400"
					></textarea>
				
				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">7. Vergangene Konflikte oder Vorgeschichte</h3>
				<p class="mt-2 text-textcol">Gab es in der Vergangenheit ähnliche Konflikte mit der anderen Partei oder mit anderen Parteien?</p>
				<select class="p-2 text-textcol bg-elevated" bind:value={partyPosition.previousConflicts}>
					<option value="Ja">Ja</option>
					<option value="Nein">Nein</option>
				</select>
				<p class="mt-2 text-textcol">Falls ja, wie wurde dieser Konflikt gelöst?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie kurz die Lösung früherer Konflikte, falls zutreffend"
					bind:value={partyPosition.previousSolutions}
					maxlength="400"
					></textarea>
				
				<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">8. Konfliktlösung</h3>
				<p class="mt-2 text-textcol">Welchen Schritt könnten Sie auf die andere Partei zugehen um die Situation zu entspannen oder sogar lösen?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie kurz die Schritte."
					bind:value={partyPosition.ownSteps}
					maxlength="400"
					></textarea>
				<p class="mt-2 text-textcol">Wenn Sie einen Wunsch frei hätten, was wäre dieser mit Bezug auf vorliegenden Fall?</p>
				<textarea 
					class="p-2 rounded-md w-full h-14 bg-elevated text-textcol placeholder-textcol" 
					placeholder="Beschreiben Sie kurz Ihren Wunsch."
					bind:value={partyPosition.wish}
					maxlength="200"
					></textarea>
			{/if}

			<h3 class="font-bold text-xl mt-4 mb-2 text-textcol">Authentifizierung</h3>
			<p class="mt-2 text-textcol">Geben Sie hier Ihr Passwort für die Verhandlung ein. Wir haben es Ihnen per E-Mail zugesendet.</p>
			<input type="password"
				class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol" 
				placeholder="Ihr Passwort aus der Einladungs-E-Mail"
				bind:value={otp}/>
		</div>
		<button
			class="btn mt-4 p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-center"
			on:click={setPartyPosition}>
			<i class="fa-solid fa-paper-plane text-inherit"></i>
			<p class="ml-2 text-inherit">Position setzen</p>
		</button>

	{:else}
		<p class="text-textcol">Lade...</p>
	{/if}
</main>