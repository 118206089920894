<script>
	let summary;
	let parties = [
		{
			id: 1, 
			name: "", 
			email: ""
		},
		{
			id: 2, 
			name: "", 
			email: ""
		}
	]
	let runningPartyId = 3;
	function removeParty(partyIndex) {
		parties = [
			...parties.slice(0, partyIndex),
			...parties.slice(partyIndex + 1)
		]
	}
	function addParty() {
		if(parties.length >= 4) {
			alert("Get Your Deal unterstützt aktuell maximal vier Parteien.");
			return;
		}
		parties = [...parties, {
			id: runningPartyId,
			name: "",
			email: ""
		}]
		runningPartyId ++;
	}
	function createNegotiation() {
		fetch(
			"/api/create?" + new URLSearchParams({
				summary,
				parties: JSON.stringify(parties)
			},
			{ signal: AbortSignal.timeout(50000) })
		).then(res => res.json()).then(j => {
			if(!j.success) {
				alert(j.msg);
				return;
			}
			window.location.replace(
				`/overview?uuid=${j.uuid}`
			)
		})
	}
</script>

<main>
	<h1 class="text-2xl font-bold">Neue Verhandlung erstellen</h1>
	
	<h2 class="text-xl font-bold mt-4">Thema</h2>
	<textarea 
		class="p-2 rounded-md w-1/2 h-28" 
		placeholder="Worum geht es?"
		bind:value={summary}
		></textarea>
	
	<h2 class="text-xl font-bold mt-4">Parteien</h2>
	{#each parties as party, i (party.id)}
		<div class="flex flex-row items-center m-2">
			<p class="font-bold">Partei {i + 1}:</p>
			<input class="mx-2 p-2 rounded-md" 
				placeholder="Name"
				bind:value={parties[i].name}/>
			<input class="mx-2 p-2 rounded-md" 
				placeholder="E-Mail"
				bind:value={parties[i].email}/>
			<button
				hidden={parties.length <= 2}
				class="p-2 rounded-md border-none hover:bg-gray-200 cursor-pointer"
				on:click={removeParty(i)}>
				<i class="fa-solid fa-trash text-black"></i>
			</button>
		</div>
	{/each}
	<button
		hidden={parties.length > 3}
		class="p-2 rounded-md border-none hover:bg-gray-200 cursor-pointer flex items-center"
		on:click={addParty}>
		<i class="fa-solid fa-user-plus text-black"></i>
		<p class="ml-2">Partei hinzufügen</p>
	</button>

	<button
		class="p-2 rounded-md border-none hover:bg-gray-200 cursor-pointer flex items-center"
		on:click={createNegotiation}>
		<i class="fa-solid fa-cart-plus text-black"></i>
		<p class="ml-2">Verhandlung erstellen</p>
	</button>
	

</main>