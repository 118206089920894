<script>
	import { Router, Link, Route } from "svelte-routing";
	import Home from "./routes/Home.svelte";
	import Prompt from "./routes/Prompt.svelte";
	import DEHome from "./de/routes/Home.svelte";
	import DECreate from "./de/routes/Create.svelte";
	import DEPosition from "./de/routes/Position.svelte";
	import DEApproval from "./de/routes/Approval.svelte";
	import DEOverview from "./de/routes/Overview.svelte";
	import FRCreate from "./fr/routes/Create.svelte";
	import FRPosition from "./fr/routes/Position.svelte";
	import FROverview from "./fr/routes/Overview.svelte";
  
	export let url = "";
  </script>
  
  <Router {url}>
	<div>
	  <Route path="/de/create" component={DECreate} />
	  <Route path="/de/position" component={DEPosition} />
	  <Route path="/de/approval" component={DEApproval} />
	  <Route path="/de/overview" component={DEOverview} />
	  <Route path="/de/" component={DEHome} />
	  <Route path="/fr/create" component={FRCreate} />
	  <Route path="/fr/position" component={FRPosition} />
	  <Route path="/fr/overview" component={FROverview} />
	  <Route path="/"><Home /></Route>
	  <Route path="/prompt" component={Prompt}/>
	</div>
  </Router>