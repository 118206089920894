<script>
	import { onMount } from "svelte";
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uuid = urlParams.get("uuid")

	let status = {};
	let chatMessages = [];

	onMount(() => {
		fetch(
			"/api/status?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			console.log(j);
			status.topic = j.neg_info[1];
			status.stage = j.neg_info[3];
			console.log(j.neg_info[3], status.stage)
			status.parties = JSON.parse(j.neg_info[2]);
			j.party_positions.forEach((hasPosition, partyIndex) => {
				status.parties[partyIndex].hasPosition = hasPosition;
			});
			j.party_approvals.forEach((pa, partyIndex) => {
				if(pa !== "" && status.stage !== 1) {
					status.parties[partyIndex].approval = JSON.parse(pa);
				}
				
			});
			console.log(status)
			if(status.stage > 0) {
				checkMessages()
			}
		})
	})

	function checkMessages() {
		console.log("checking messages...")
		fetch(
			"/api/llmlog?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			chatMessages = JSON.parse(j[1]);
			status.stage = j[2];
			if(status.stage === 1) {
				setTimeout(checkMessages, 5000);
			}
		})
	}
</script>

<main class="flex flex-col items-center bg-base w-full min-h-screen p-4">
	<div class="flex flex-col items-start md:landscape:w-2/3">
		<h1 class="font-bold text-2xl mt-4 text-textcol">Get Your Deal: Verhandlungsübersicht</h1>
		<p class="text-textcol mt-2">Verhandlungs-Code: {uuid}</p>
		
		{#if Object.keys(status).length > 0}
			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Parteien</h2>
			{#each status.parties as party, partyIndex (party.id)}
				<div class="flex items-center py-2">
					<p class="text-textcol">Partei {partyIndex + 1}: 
						{party.name} ({party.email})</p>
					{#if status.stage === 0}	
						<div 
							class="ml-2"
							hidden={!party.hasPosition}
							title="Diese Partei hat eine Position angegeben."
							>
							<i class="fa-solid fa-circle-check text-green-200"></i>
						</div>
						<div class="btn rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol ml-2">
							<a
								href={
									"/de/position?" + new URLSearchParams({
										uuid,
										pnum: partyIndex + 1
									})
								}
								class="text-textcol" style="text-decoration: none; color: inherit;"
							>Position setzen</a>
						</div>
						
						{:else if status.stage === 2}
							{#if party.approval}
								{#if party.approval.approval}
									<div 
										class="ml-2"
										title="Diese Partei hat dem Vorschlag zugestimmt."
										>
										<i class="fa-solid fa-circle-check text-green-200"></i>
									</div>
									{:else}
										<div 
											class="ml-2"
											title="Diese Partei hat dem Vorschlag nicht zugestimmt."
											>
											<i class="fa-solid fa-circle-xmark text-red-200"></i>
										</div>
								{/if}
							{/if}
							<div class="btn rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol ml-2">
								<a
									href={
										"/de/approval?" + new URLSearchParams({
											uuid,
											pnum: partyIndex + 1
										})
									}
									class="text-textcol" style="text-decoration: none; color: inherit;"
								>Vorschlag bewerten</a>
							</div>
							
						{:else if status.stage === 3}
						<div 
							class="ml-2"
							title="Diese Partei hat dem Vorschlag zugestimmt."
							>
							<i class="fa-solid fa-circle-check text-green-200"></i>
						</div>
					{/if}
				</div>
			{/each}

			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Theman der Verhandlung</h2>
			<p class="text-textcol">{status.topic}</p>

			<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Stand der Verhandlung</h2>
			{#if status.stage === 0}
				<p class="text-textcol">Es haben noch nicht alle Parteien ihre Position festgelegt.</p>
				{:else if status.stage >= 1}
				{#each chatMessages as msg}
					<p class="font-bold text-textcol mt-2">Verhandler von {msg.party}</p>
					<p class="text-textcol text-justify">{msg.msg.replaceAll("#AGREE#", "").trim()}</p>
					{#if msg.msg.trim() === "#AGREE#"}
						<p class="text-green-200 text-justify">Der Verhandler von {msg.party} hat zugestimmt.</p>
						{:else if msg.msg.trim() === "#FAILED#"}
							<p class="text-red-200 text-justify">Der Verhandler von {msg.party} hält die Verhandlung für gescheitert.</p>
					{/if}
				{/each}
			{/if}

		{:else}
			<p class="text-textcol">Lade...</p>
		{/if}
	</div>
</main>