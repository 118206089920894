<script>
	let uu;
	function redirectToOverview() {
		window.location.replace(`/de/overview?uuid=${uu}`);
	}
	function redirectOnEnter(event) {
		if(event.key === "Enter") {
			redirectToOverview
		}
 	}
</script>

<main class="flex flex-col items-center bg-base w-screen min-h-screen m-0 p-4">
	<h1 class="font-bold text-2xl my-4 text-textcol">Get Your Deal</h1>
	
	<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">getyourdeal.ai: Ihr smarter Verhandlungsassistent</h2>
	<p class="text-textcol text-justify">Mit <b class="font-bold">getyourdeal.ai</b> bieten wir eine innovative KI-gestützte Plattform, die Ihnen bei der Erreichung optimaler Verhandlungsergebnisse hilft. In Verhandlungsführung geschulte Language Language Models (LLMs) verhandeln in Echtzeit an Ihrer Stelle und entwickeln massgeschneiderte, effiziente und faire Kompromissvorschläge, die Sie online nachverfolgen können. Sie behalten jederzeit die Kontrolle und entscheiden, ob Sie die von der KI vorgeschlagene Lösung annehmen möchten oder nicht. Ideal für alle, die effiziente Verhandlungen suchen und dabei Zeit und Geld sparen möchten – <b class="font-bold">getyourdeal.ai</b> bringt Sie schneller zum Deal.</p>

	<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">Funktionsweise</h2>
	<p class="text-textcol text-justify">Erfassen Sie auf getyourdeal.ai Ihre Verhandlung und geben Sie Ihre E-Mail-Adresse sowie diejenige des Verhandlungspartners ein. Nach der Registrierung der Verhandlung auf getyourdeal.ai erhalten Sie sowie Ihr Verhandlungspartner per Mail einen individuellen Zugangschlüssel zur Verhandlung. Parteien füllen in der Folge eine Eingabemaske aus, um ihre Positionen, Interessen und Prioritäten für die Verhandlung anzugeben. Sie können dabei wählen zwischen einer Kurzeingabemaske und einer ausführlichen Positionsbeschreibung. Ihre Angaben werden selbstverständlich der anderen Partei nicht offengelegt und Ihr Verhandlungsgegner hat keine Möglichkeit, Ihre Angaben einzusehen. Ihre Angaben und detaillierten Informationen dienen als Eingabedaten für die KI-Modelle, die auf Grundlage dieser Inputs massgeschneiderte Verhandlungslösungen entwickeln und anbieten. Die LLMs wurde von Verhandlungsexperten so angewiesen, dass sie anhand praxisorientierter und wissenschaftlicher Modelle konstruktiv und lösungsorientiert für ihren jeweiligen Auftraggeber verhandeln.</p>

	<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">Neue Verhandlung</h2>
	<div class="btn p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-start">
		<a href="/de/create" class="text-textcol" style="text-decoration: none; color: inherit;">
			Hier können Sie eine neue Verhandlung erstellen
		</a>
	</div>
	
	<h2 class="font-bold text-xl mt-4 mb-2 text-textcol place-self-start">Bestehende Verhandlung aufrufen</h2>
	<div class="flex flex-row items-center place-self-start w-2/3">
		<input 
		bind:value={uu}
		class="p-2 h-12 w-2/3 rounded-md flex-grow bg-elevated text-textcol placeholder-textcol"
		placeholder="Geben Sie hier den Code ein, um zu einer bestehenden Verhandlung zu gelangen."
		on:keyup={redirectOnEnter}/>
		<button class="btn rounded-md p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol ml-2"
		on:click={redirectToOverview}>
			Verhandlung aufrufen
		</button>
	</div>
	
	
</main>