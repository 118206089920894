<script>
	import { onMount } from "svelte";
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uuid = urlParams.get("uuid")
	const partyNum = parseInt(urlParams.get("pnum"))
	
	let otp;

	let status = {};
	let chatMessages = [];
	let approval = false;
	let selectedMsg;
	let comment;

	onMount(() => {
		console.log("test");
		fetch(
			"/api/status?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			console.log(j);
			if(j.neg_info[3] !== 2) {
				alert("Sie können in dieser Phase nicht über die Zustimmung entscheiden!");
				return;
			}

			status.topic = j.neg_info[1];
			status.partyInfo = JSON.parse(j.neg_info[2])[partyNum - 1];
			console.log(JSON.parse(j.neg_info[2]))
			checkMessages()
		})
	})

	function checkMessages() {
		console.log("checking messages...")
		fetch(
			"/api/llmlog?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			chatMessages = JSON.parse(j[1]);
			status.stage = j[2];
			if(status.stage === 1) {
				setTimeout(checkMessages, 5000);
			}
		})
	}

	function checkForNegotiationStart() {
		fetch(
			"/api/status?" + new URLSearchParams({
				uuid
			})
		).then(res => res.json()).then(j => {
			if(j.neg_info[3] == 1) {
				alert("Die Verhandlung hat begonnen, Sie werden weitergeleitet!");
				window.location.replace(
					`/de/overview?uuid=${uuid}`
				);
			}
		})
	}

	function setPartyApproval() {
		const fullPosition = {
			approval,
			msg: selectedMsg,
			comment: comment ? comment : ""
		}
		fetch(
			"/api/setapproval?" + new URLSearchParams({
				uuid,
				party_num: partyNum,
				otp: otp.trim(),
				approval: JSON.stringify(fullPosition)
			})
		).then(res => res.json()).then(j => {
			if(j.success) {
				alert("Ihre Bewertung wurde gespeichert. Sie werden weitergeleitet...")
				window.location.replace(
					`/de/overview?uuid=${uuid}`
				)
			}
			else {
				alert(j.msg);
			}
		});
		setTimeout(checkForNegotiationStart, 2000);
	}
</script>

<main class="flex flex-col min-h-screen md:landscape:h-screen m-0 p-4 bg-base">
	{#if status.partyInfo}
		<div class="w-full max-h-1/4 min-h-1/4">
			<h1 class="font-bold text-2xl text-textcol">Get Your Deal: Verhandlungsübersicht</h1>
			<p class="text-textcol mt-2">Verhandlungs-Code: {uuid}</p>
			<p class="font-bold mt-4 text-textcol">Bewertung für {status.partyInfo.name} ({status.partyInfo.email})</p>
			<p class="font-bold mt-4 text-textcol">Thema der Verhandlung</p>
			<p class="text-textcol">{status.topic}</p>
		</div>
		
		<div class="flex flex-col md:landscape:flex-row w-full md:landscape:h-3/4 text-textcol">
			<h2 class="font-bold mt-4 text-xl block md:landscape:hidden">Verhandlungsverlauf</h2>
			<div class="flex flex-col w-full md:landscape:w-1/2 md:landscape:overflow-y-scroll md:landscape:h-full">
				{#each chatMessages as msg}
					<div class="flex flex-col p-2"
					class:disapprovalMsg={!approval && msg.party === status.partyInfo.name}
					class:disapprovalMsgSelected={!approval && msg.msg === selectedMsg}
					on:click={() => {if(msg.party === status.partyInfo.name) selectedMsg = msg.msg}}>
						<p class="font-bold">{msg.party === status.partyInfo.name ? 'Ihr Verhandler' : 'Verhandler von ' + msg.party}</p>
						<p>{msg.msg.replaceAll("#AGREE#", "").trim()}</p>
						{#if msg.msg.trim() === "#AGREE#"}
							<p class="text-green-200">{msg.party} hat zugestimmt.</p>
							{:else if msg.msg.trim() === "#FAILED#"}
								<p class="text-red-200">{msg.party} hält die Verhandlung für gescheitert.</p>
						{/if}
					</div>
					
				{/each}
			</div>
			<h2 class="font-bold mt-4 text-xl block md:landscape:hidden">Ihre Bewertung</h2>
			<div class="flex flex-col w-full md:landscape:w-1/2 md:landscape:px-4 overflow-y-auto h-1/2 md:landscape:h-full">
				<div class="flex flex-row w-full justify-center items-center mb-4">
					<p class="text-red-200 flex-grow">Ich bin mit dem Vorschlag nicht einverstanden.</p>
					<input 
					type="checkbox" 
					bind:checked={approval}
					class="mx-2 toggle toggle-md bg-accent hover:bg-elevated"/>
					<p class="text-green-200 flex-grow text-right">Ich bin mit dem Vorschlag einverstanden.</p>
				</div>
				{#if approval}
					<p>Schön, dass der Vorschlag Ihren Vorstellungen entspricht. Bitte geben Sie ihr Passwort ein und klicken/tippen Sie auf den Button, um Ihre Zustimmung zu speichern.</p>
				{:else}
					<p>Schade, dass der Vorschlag nicht Ihren Vorstellungen entspricht. Bitte wählen Sie durch Klicken/Tippen im Verhandlungsverlauf diejenige Nachricht Ihres Verhandlers aus, ab der Sie eine andere Verhandlungsführung wünschen. Sie können zudem in einem Kommentar an Ihren Verhandler erläutern, was Sie anders machen würden.</p>
					{#if selectedMsg}
						<h2 class="font-bold mt-2">Ausgewählte Nachricht</h2>
						<p>{selectedMsg.replaceAll("#AGREE#", "").trim()}</p>
						{#if selectedMsg.trim() === "#AGREE#"}
							<p class="text-green-200">Ihr Verhandler hat zugestimmt.</p>
							{:else if selectedMsg.trim() === "#FAILED#"}
								<p class="text-red-200">Ihr Verhandler hält die Verhandlung für gescheitert.</p>
						{/if}
						<h2 class="font-bold mt-2">Ihr Kommentar</h2>
						<textarea 
							class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol" 
							placeholder="Beschreiben Sie kurz, wie Ihr Verhandler die Verhandlung besser führen kann."
							bind:value={comment}
							maxlength="500"
							></textarea>
					{/if}
				{/if}
				<h3 class="font-bold mt-4">Authentifizierung</h3>
				<p class="mt-2">Geben Sie hier Ihr Passwort für die Verhandlung ein. Wir haben es Ihnen per E-Mail zugesendet.</p>
				<input type="password"
					class="p-2 rounded-md w-full h-12 bg-elevated text-textcol placeholder-textcol mt-2" 
					placeholder="Ihr Passwort aus der Einladungs-E-Mail"
					bind:value={otp}/>
				{#if approval}
					<button
						class="my-2 w-42 place-self-center p-2 rounded-md border-green-800 border-2 bg-green-800 text-white hover:bg-green-400 cursor-pointer flex items-center"
						on:click={setPartyApproval}>
						<i class="fa-solid fa-circle-check text-white"></i>
						<p class="ml-2">Vorschlag zustimmen</p>
					</button>
					{:else if selectedMsg}
					<button
						class="my-2 w-42 place-self-center p-2 rounded-md border-red-800 border-2 bg-red-800 text-white hover:bg-red-400 cursor-pointer flex items-center"
						on:click={setPartyApproval}>
						<i class="fa-solid fa-circle-xmark text-white"></i>
						<p class="ml-2">Vorschlag ablehnen</p>
					</button>
				{/if}
				
			</div>
		</div>

	{:else}
		<p>Lade...</p>
	{/if}
</main>