<script>
	let summary;
	let parties = [
		{
			id: 1, 
			name: "", 
			email: ""
		},
		{
			id: 2, 
			name: "", 
			email: ""
		}
	]
	let runningPartyId = 3;
	function removeParty(partyIndex) {
		parties = [
			...parties.slice(0, partyIndex),
			...parties.slice(partyIndex + 1)
		]
	}
	function addParty() {
		if(parties.length >= 4) {
			alert("Get Your Deal unterstützt aktuell maximal vier Parteien.");
			return;
		}
		parties = [...parties, {
			id: runningPartyId,
			name: "",
			email: ""
		}]
		runningPartyId ++;
	}
	function createNegotiation() {
		fetch(
			"/api/create?" + new URLSearchParams({
				summary,
				parties: JSON.stringify(parties)
			},
			{ signal: AbortSignal.timeout(50000) })
		).then(res => res.json()).then(j => {
			if(!j.success) {
				alert(j.msg);
				return;
			}
			window.location.replace(
				`/de/overview?uuid=${j.uuid}`
			)
		})
	}
</script>

<main class="flex flex-col items-center bg-base w-full min-h-screen p-4">
	<div class="flex flex-col items-start md:landscape:w-2/3">
		<h1 class="font-bold text-2xl my-4 text-textcol">Neue Verhandlung erstellen</h1>
	
		<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Thema</h2>
		<p class="mb-2 text-textcol">Bitte beachten Sie: Die Themenbeschreibung dient nur der Information der anderen Partei. Ihre Beschreibung wird zur Wahrung der Neutralität nicht an die KI-Verhandler weitergegeben.</p>
		<textarea 
			class="p-2 rounded-md w-full h-28 bg-elevated text-textcol placeholder-textcol" 
			placeholder="Worum geht es?"
			bind:value={summary}
			></textarea>
		
		<h2 class="font-bold text-xl mt-4 mb-2 text-textcol">Parteien</h2>
		{#each parties as party, i (party.id)}
			<div class="flex flex-col md:landscape:flex-row items-start md:langscape:items-center mb-2">
				<p class="font-bold text-textcol">Partei {i + 1}:</p>
				<input class="mt-2 md:landscape:mt-0 md:landscape:mx-2 p-2 h-12 rounded-md bg-elevated text-textcol placeholder-textcol" 
					placeholder="Name"
					bind:value={parties[i].name}/>
				<input class="mt-2 md:landscape:mt-0 md:landscape:mx-2 p-2 h-12 rounded-md bg-elevated text-textcol placeholder-textcol" 
					placeholder="E-Mail"
					bind:value={parties[i].email}/>
				<button
					hidden={parties.length <= 2}
					class="mt-2 md:landscape:mt-0 btn rounded-md h-12 w-12 p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol"
					on:click={removeParty(i)}>
					<i class="fa-solid fa-trash text-textcol"></i>
				</button>
			</div>
		{/each}
		<button
			hidden={parties.length > 3}
			class="btn rounded-md h-12 p-2 bg-base border border-accent text-textcol hover:bg-elevated hover:text-textcol flex items-center"
			on:click={addParty}>
			<i class="fa-solid fa-user-plus text-textcol"></i>
			<p class="ml-2 text-textcol">Partei hinzufügen</p>
		</button>
	
		<button
			class="btn mt-4 p-2 h-12 rounded-md bg-accent border border-accent text-textsec hover:bg-elevated hover:text-textcol place-self-center"
			on:click={createNegotiation}>
			<i class="fa-solid fa-cart-plus text-inherit"></i>
			<p class="ml-2 text-inherit">Verhandlung erstellen</p>
		</button>
	</div>
</main>